// 对路由参数进行编码处理
export const encodeParams = (params = {}) => {
  if (!params) return ''
  let result = {}
  for (let key in params) {
    let value = params[key]
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue
    }
    result[encodeURIComponent(key)] = encodeURIComponent(JSON.stringify(value))
  }
  console.log('路由参数编码', result)
  return result
}

// 对路由参数进行解码处理
export const decodeParams = (params = {}) => {
  if (!params) return {}
  const result = {}
  for (let key in params) {
    let value = params[key]
    // 判断键值是否通过encodeURIComponent加密过的
    if (
      Object.prototype.toString.call(value) === '[object String]' &&
      (value.charAt(0) === '%' || ['true', 'false'].includes(value))
    ) {
      result[decodeURIComponent(key)] = JSON.parse(decodeURIComponent(value))
    } else {
      result[key] = value
    }
  }
  console.log('路由参数解码', result)
  return result
}

// 小程序路径拼接
export const spliceFormatPath = (url, data = {}) => {
  let path = url
  const pathIndex = path.indexOf('?')
  if (pathIndex === -1) {
    if (path.indexOf('.html') === -1) {
      path = `${path}.html`
    }
    if (
      Object.prototype.toString.call(data) === '[object Object]' &&
      JSON.stringify(data) !== '{}'
    ) {
      path = `${path}?`
    }
  } else {
    if (path.indexOf('.html') === -1) {
      let pathArray = path.split('')
      const index = pathArray.indexOf('?')
      pathArray.splice(index, 0, '.html')
      path = pathArray.join('')
    }
  }
  const params = []
  for (let key in data) {
    params.push(`${key}=${data[key]}`)
  }
  return path + params.join('&')
}
