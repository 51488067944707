<template>
  <div class="container">
    <ComponentView ref="component" />
  </div>
</template>

<script>
import ComponentView from './component.vue'

export default {
  name: 'material-library',
  components: { ComponentView },
  data() {
    return {
      // isReady: false // 是否完成页面初次渲染
    }
  }
  // onLoad() {},
  // onShow() {
  // 	if (this.$refs.component && this.$refs.component.activated && this.isReady) {
  // 		this.$refs.component.activated()
  // 	}
  // },
  // onReady() {
  // 	this.isReady = true
  // }
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
}
</style>
