import { getUploadByMedia } from '@/service/upload.service'
import { spliceFormatPath } from '@/utils/router.js'
import { ImagePreview, Toast } from 'vant'
import {
  getMiniprogramCode,
  getPosterMediaId
} from '@/service/quickSend.service'

// 格式化任务列表媒体数据
export function formatTaskItem(data = []) {
  if (!data || !data.length) return []
  const formatData = [...data]
  for (let i = 0; i < formatData.length; i++) {
    const {
      type,
      title,
      appid,
      url,
      file_url,
      upload_url,
      description,
      qw_link_title,
      media_url
    } = formatData[i]

    if (!file_url) formatData[i].file_url = upload_url || media_url
    if (
      type === 'we_applets' ||
      type === 'commodity' ||
      type === 'coupon' ||
      type === 'activity'
    ) {
      formatData[i].type = 'MINIPROGRAM'
      formatData[i].qw_miniprogram_title = title
      formatData[i].qw_miniprogram_appid = appid
      formatData[i].qw_miniprogram_page = url
    } else {
      switch (type) {
        case 'images':
          formatData[i].type = 'IMAGE'
          break
        case 'web_page':
          formatData[i].type = 'LINK'
          formatData[i].qw_link_title = title
          formatData[i].qw_link_desc = description
          formatData[i].qw_link_url = url
          break
        case 'video':
          formatData[i].type = 'VIDEO'
          formatData[i].title = qw_link_title
          break
        case 'file':
          formatData[i].type = 'FILE'
          // formatData[i].title = formatData[i].qw_link_title
          break
        case 'GOODS_POSTER':
          break
      }
    }
  }
  return formatData
}

// 判断wechat文件类型
export function formatType(medisType) {
  const typeEnum = {
    TEXT: 'text',
    IMAGE: 'image',
    LINK: 'news',
    VIDEO: 'video',
    MINIPROGRAM: 'miniprogram',
    FILE: 'news',
    GOODS_POSTER: 'image'
  }
  return typeEnum[medisType] || 'text'
}

/**
 * 把params转换成URL参数
 * */
export const encodeParams = (params = {}) => {
  if (!params) return ''
  let result = []
  for (let key in params) {
    let value = params[key]
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue
    }
    result.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value))
    )
  }
  return result.length ? '?' + result.join('&') : ''
}

// 分享(发送)参数配置 -----h5群发参数与分享不同 link != news
export async function formatSendParams(type, item = {}, share_data_id) {
  const {
    file_url,
    file_name,
    file_id,
    source_id,
    file_suffix,
    media_id,
    qw_link_url,
    qw_link_title,
    qw_link_desc,
    qw_miniprogram_appid,
    qw_miniprogram_page,
    qw_miniprogram_title,
    content,
    goods_id, // 商品id (商品附件)
    coupon_id, // 优惠券id (优惠券附件)
    activity_id, // 活动id (活动附件)
    task_id, // 任务id
    strategy_id, // 策略id
    sale_script_id, // 素材库id
    customer_id, // 客户id
    chart_id, // 客户群id
    source, // 发送类型 任务: task, 策略: strategy, 素材库: sale_script
    poster_id, // 海报id
    poster_info = {} // 海报信息
  } = item

  // 缺少http头部，补充
  if (file_url && file_url.indexOf('http') === -1) {
    item.file_url = 'https://' + file_url
  }

  let params = {}
  if (['image', 'video'].includes(type)) {
    let mediaId = media_id
    try {
      if (item.type === 'GOODS_POSTER') {
        // 商品海报
        const posterUrl = await createGoodsPoster(item)
        const { data = {} } = await getPosterMediaId({ img_base64: posterUrl })
        if (data && data.media_id) mediaId = data.media_id
        params[type] = {
          mediaid: mediaId
        }
        params['data'] = { ...item }
        console.log('posterUrl', posterUrl)
      } else {
        // 下游企业需要拿上游的media_id换取上游media_id
        const chainCorpId = localStorage.getItem('chain_corp_id')
        if (chainCorpId && String(chainCorpId) !== '0') {
          const { data, code } = await getUploadByMedia({
            file_type: type === 'image' ? '1' : '2',
            file_id: source_id || file_id
          })

          if (code == 200) {
            mediaId = data.media_id
          }
        }
        params[type] = {
          mediaid: mediaId
        }
      }
    } catch (error) {
      console.log(error)
      params[type] = {
        mediaid: mediaId
      }
    }
  } else if (type === 'news') {
    // 普通h5
    if (item.type === 'LINK') {
      let imgUrl = file_url
        ? file_url
        : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      params[type] = {
        link: qw_link_url, //H5消息页面url 必填
        title: qw_link_title, //H5消息标题
        desc: qw_link_desc || '', //H5消息摘要
        imgUrl: imgUrl //H5消息封面图片URL
      }
    } else {
      // 文件类型附件，当做h5发出去
      // 环境不同需要更换
      let baseURL = 'https://we-customer-guide.freshgood.cn/preview-page?file='
      let imageUrl = getFileCover(item)
      params[type] = {
        link: baseURL + file_url + `&type=${file_suffix}`, //H5消息页面url 必填
        title: file_name, //H5消息标题
        desc: qw_link_desc || '', //H5消息摘要
        imgUrl: imageUrl //H5消息封面图片URL
      }
    }
  } else if (type == 'miniprogram') {
    const query = {} // 小程序参数
    const member_id = localStorage.getItem('member_id') // 成员id

    if (member_id) query.member_id = member_id
    if (customer_id) query.customer_id = customer_id
    if (chart_id) query.chart_id = chart_id
    if (source) query.source = source
    // 存在任务id
    if (task_id) {
      query.task_id = task_id
      // 商品附件
      if (goods_id) query.goods_id = goods_id
      // 优惠券附件
      if (coupon_id) query.coupon_id = coupon_id
      // 活动附件
      if (activity_id) query.activity_id = activity_id
    }
    // 存在埋点id
    if (share_data_id) query.share_data_id = share_data_id

    let path = spliceFormatPath(qw_miniprogram_page, query)
    params[type] = {
      appid: qw_miniprogram_appid, //小程序的appid
      title: qw_miniprogram_title, //小程序消息的title
      imgUrl: file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page: path
    }
  } else if (type == 'text' && content) {
    params[type] = { content }
  } else {
    return ''
  }
  return params
}

// 发送时获取图片封面
export function getFileCover(item) {
  if (['doc', 'docx'].includes(item.file_suffix)) {
    return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
  } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
    return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
  } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
    return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
  } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
    return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
  } else {
    return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
  }
}

// 生成商品海报
const createGoodsPoster = ({
  poster_id,
  poster_info = {},
  task_id,
  strategy_id,
  sale_script_id,
  source
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Toast.loading({
      //   message: '正在生成海报',
      //   duration: 0,
      //   forbidClick: true
      // })
      if (!poster_info) return false
      const {
        background_width,
        background_height,
        background_file_url,
        set_info = {},
        dep_name
      } = poster_info
      const {
        avatar,
        qr_code,
        member_info,
        member_department,
        is_avatar,
        is_member
      } = set_info
      const storeData = JSON.parse(localStorage.getItem('vuex')) || {} // 缓存信息
      const member_id = localStorage.getItem('member_id') // 成员id
      const memberInfo = storeData.user.info || {} // 成员信息
      // const memberInfo = {
      //   qw_userid: 'JiangYongWei',
      //   qw_name: '江勇威',
      //   qw_gender: '1',
      //   qw_avatar:
      //     'https://wework.qpic.cn/bizmail/BsofmpFJ7JRDLp6CWuuNqFwqIrD9l32rZ0jfaMhNktjoMToZic7VjiaQ/0',
      //   qw_thumb_avatar:
      //     'https://wework.qpic.cn/bizmail/BsofmpFJ7JRDLp6CWuuNqFwqIrD9l32rZ0jfaMhNktjoMToZic7VjiaQ/100'
      // }
      // console.clear()
      const canvas = document.createElement('CANVAS')
      const context = canvas.getContext('2d')
      canvas.setAttribute('width', background_width)
      canvas.setAttribute('height', background_height)
      // 生成底部图片
      const backImg = new Image()
      backImg.setAttribute('crossOrigin', 'Anonymous')
      backImg.src = background_file_url
      // backImg.src = '/aliyuncs' + background_file_url.replace(/^https:\/\/[^/]+/, '')
      backImg.onload = async () => {
        context.drawImage(backImg, 0, 0, background_width, background_height)

        // 生成成员头像
        if (is_avatar && memberInfo.qw_avatar) {
          const avatarImg = new Image()
          avatarImg.setAttribute('crossOrigin', 'Anonymous')
          // nginx代理解决头像跨域问题
          avatarImg.src =
            '/wechat_image' +
            memberInfo.qw_avatar.replace(/^https:\/\/[^/]+/, '')
          avatarImg.onload = () => {
            if (avatar.shape === 'circle') {
              context.save()
              let radius = avatar.width / 2
              let x = avatar.left + radius
              let y = avatar.top + radius
              context.arc(x, y, radius, 0, 2 * Math.PI)
              context.clip()
              context.drawImage(
                avatarImg,
                x - radius,
                y - radius,
                radius * 2,
                radius * 2
              )
              context.restore()
            } else {
              context.drawImage(
                avatarImg,
                avatar.left,
                avatar.top,
                avatar.width,
                avatar.height
              )
            }
          }
        }
        // 生成成员姓名
        if (is_member && memberInfo.qw_name) {
          context.fillStyle = `rgb(${member_info.color})`
          context.font = `normal ${member_info.weight} ${member_info.font_size}px Arial`
          context.fillText(
            memberInfo.qw_name,
            member_info.left,
            member_info.top + member_info.font_size
          )
        }
        // 生成成员部门
        if (is_member && memberInfo.qw_name) {
          context.fillStyle = `rgb(${member_department.color})`
          const fontWidth = context.measureText(memberInfo.qw_name).width // 获取姓名文本宽度

          const symbolWidth = (member_department.font_size * 3) / 4 // 符号的fontSize是部门的3/4
          const differWidth = member_department.font_size / 4 / 2 // 符号和部门相差的宽度/2
          context.font = `normal ${member_department.weight} ${symbolWidth}px Arial` // 符号的fontSize是部门的2/3
          context.fillText(
            '@',
            member_info.left + fontWidth,
            member_department.top + symbolWidth + differWidth
          )
          context.font = `normal ${member_department.weight} ${member_department.font_size}px Arial`
          const symbolIndex = dep_name.indexOf('@')
          const departmentName = dep_name.substr(
            symbolIndex > -1 ? symbolIndex + 1 : 0,
            dep_name.length
          )
          context.fillText(
            departmentName,
            member_info.left + fontWidth + symbolWidth,
            member_department.top + member_department.font_size
          )
        }

        // 生成二维码
        const miniprogramCodeBody = {
          poster_id,
          member_id,
          relate_id: task_id || strategy_id || sale_script_id || '',
          relate_class: source
        }
        const { data } = await getMiniprogramCode(miniprogramCodeBody) // 请求小程序二维码
        if (data.img_base64) {
          const codeImg = new Image()
          codeImg.setAttribute('crossOrigin', 'Anonymous')
          codeImg.src = data.img_base64
          // codeImg.src = data.img_base64 + `?timestamp= ${Date.now()}`
          codeImg.onload = () => {
            context.drawImage(
              codeImg,
              qr_code.left,
              qr_code.top,
              qr_code.width,
              qr_code.height
            )

            // Toast.clear()
            const posterMerge = canvas.toDataURL('image/png')
            // ImagePreview({
            //   images: [posterMerge],
            //   closeable: true
            // })
            resolve(posterMerge)
          }
        }
      }
    } catch (error) {
      // Toast.clear()
      reject(error)
    }
  })
}
